import i18n, { changeLanguage } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: "ar",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          welcome: "Welcome",
          pages: "Pages",
          dashboard: "Dashboard",
          DHASBOARD: "DASHBOARD",
          dashboardSubtitle: "Welcome to your dashboard",
          TEAM: "TEAM",
          DRIVERS: "DRIVERS",
          driverFormTitle: "ADD DRIVER",
          driverFormSubtitle: "Add a New Driver",
          NOTIFICATIONS: "NOTIFICATIONS",
          notificationTitle: "Important Notifications Page",
          deductionSalaryTitle: "DEDUCTION SALARY",
          manageTeamMembers: "Managing the Team Members",
          manageDriverMembers: "Managing the Drivers",
          deductionSalaryRequest: "Deduction Salary Requests Page",
          manageTeam: "Manage Team",
          manageDrivers: "Manage Drivers",
          userProfileTitle: "USER PROFILE",
          userProfileSubtitle: "View/Update User Information",
          deductionInvoices: "Deduction Invoices",
          driversInvoices: "Drivers Invoices",
          notifications: "Notifications",
          settings: "Settings",
          forms: "Forms",
          salaries: "Salaries",
          contactAndMessages: "Contact and Messages",
          employeesManagement: "Employees Management",
          accountingPages: "Accounting Pages",
          archive: "Archive",
          profileForm: "Team Form",
          driversForm: "Drivers Form",
          deduction: "Deduction",
          deductionForm: "Deduction Form",
          contact: "Contact",
          messages: "Messages",
          receivedMessages: "Received Messages",
          sentMessages: "Sent Messages",
          noSearchResults: "No search results found",
          attachFile: "Attach File",
          noFileAvailable: "No file available",
          viewFile: "View File",
          title: "Title",
          sender: "Sender",
          date: "Date",
          actions: "Actions",
          subject: "Subject",
          searchMessagesBySubject: "Search Messages by Subject",
          invoicesArchive: "Invoices Archive",
          invoicesArchiveSubtitle: "List of archived invoices",
          invoiceDetails: "Invoice Details",
          invoiceDetailsByDate: "Invoice Details by Date",
          message: "Message",
          send: "Send",
          unknownSender: "Unknown Sender",
          companyFiles: "Company Files",
          searchingArchive: "Searching in Archive",
          companyFilesSubtitle: "Company Files Page",
          data: "Data",
          users: "Users",
          role: "User Role in the System",
          admins: "ADMINS",
          managers: "MANAGERS",
          accountants: "ACCOUNTANTS",
          employees: "EMPLOYEES",
          Admin: "Admin",
          Manager: "Manager",
          Accountant: "Accountant",
          Employee: "Employee",
          driver: "Driver",
          drivers: "Drivers",
          login: "LOGIN",
          logout: "Logout",
          loggingIn: "Logging in...",
          noEditPermission: "You don't have permission to edit this field",
          invalidCredentials: "Invalid email or password",
          userNotFound: "User not found",
          networkError: "Network error. Please check your internet connection",
          generalError: "An error occurred. Please try again",
          /////////////
          email: "Email",
          password: "Password",
          confirmPassword: "Confirm Password",
          createNewUser: "Create New User",
          phone: "Phone Number",
          name: "Name",
          civilId: "Civil ID",
          passport: "Passport",
          mainSalary: "Main Salary",
          accessLevel: "Access Level",
          actions: "Actions",
          deductionReject: {
            Heading: "Deduction Rejected",
            Message: "Deduction request has been rejected",
          },
          "deductionReject Heading": "Deduct Salary - {{targetName}}",
          "deductionReject Message":
            "Deduction request rejected from {{senderName}} on {{date}}",
          deductionApprove: "Deduction Approved",
          Heading: "Heading",
          Message: "Message",
          deductionRejectHeading: "{{targetName}} Deduction Alert",
          deductionRejectMessage:
            "{{senderName}} has rejected deduction request on {{date}}",
          /////////////
          required: "required",
          firstName: "First Name",
          lastName: "Last Name",
          optionalEmail: "Email (optional)",
          idNumber: "ID Number",
          idExpiryDate: "ID Expiry Date",
          passportExpiryDate: "Passport Expiry Date",
          contractExpiryDate: "Contract Expiry Date",
          driverLicenseExpiryDate: "Driver License Expiry Date",
          carPlateNumber: "Car Plate Number",
          carRegisteration: "Car Registeration",
          carRegisterationExpiryDate: "Car Registeration Expiry Date",
          workPass: "Work Pass",
          gasCard: "Gas Card",
          healthInsuranceExpiryDate: "Health Insurance Expiry Date",
          healthInsurance: "Health Insurance",
          carType: "Car Type",
          employeeCompanyNumber: "Employee Company Number",
          iban: "IBAN",
          vehicle: "Vehicle",
          car: "Car",
          bike: "Bike",
          Car: "Car",
          Bike: "Bike",
          contractType: "Contract Type",
          talabat: "Talabat",
          others: "Others",
          talabatId: "Talabat ID Number",
          uploadFile: "Upload File",
          changeImage: "Change Image",
          removeImage: "Remove Image",
          uploadImage: "Upload Image",
          invalidFileSelected:
            "Invalid file selected. Please upload image file",
          profileImageRemoved: "Profile image is removed successfully!",
          profileImageRemovalFailed:
            "Can't remove profile image, you can try later!",
          viewUploadedFile: "View Uploaded File",
          update: "Update",
          delete: "Delete",
          confirmDeletePettyCashTitle: "Delete Petty Cash",
          confirmDeletePettyCashMessage:
            "Are you sure you want to delete this petty cash?",
          cancel: "Cancel",
          deletePettyCashFulfilled: "Petty cash is deleted successfully!",
          deletePettyCashRejected:
            "Can't delete a petty cash, you can try later!",
          add: "Add",
          edit: "Edit",
          addNewDriver: "Add New Driver",
          /////////////
          additionalSalary: "Additional Salary",
          talabatDeductionAmount: "Talabat Deduction Amount",

          companyDeductionAmount: "Company Deduction Amount",
          pettyCashDeductionAmount: "Petty Cash Deduction",
          deductionReason: "Deduction Reason",
          deductionDate: "Deduction Date",
          deductionDateRequired: "Deduction date is required",
          preview: "Preview",
          uploadNewFile: "Upload a New File",
          /////////////
          willExpireOn: "will expire on",
          expirationAlert: "Expiration Alert",
          /////////////
          createUserTitle: "CREATE USER",
          createUserSubtitle: "Create a New User Profile",
          /////////////
          firstNameIsRequired: "First name is required",
          lastNameIsRequired: "Last name is required",
          invalidEmail: "Invalid email",
          emailIsRequired: "Email is required",
          invalidPhoneNumber: "Invalid Phone Number",
          phoneIsRequired: "Phone number is required",
          identificationIsRequired: "ID number is required",
          passportIsRequired: "Passport number is required",
          contractExpiryDateIsRequired: "Contract expiry date is required",
          roleIsRequired: "Role selection is required",
          mainSalaryIsRequired: "Main salary is required",
          passwordMinLength: "Password minimum length is 6 characters",
          passwordIsRequired: "Password is requitred",
          passwordMustMatch: "Passwords must match",
          confirmPasswordIsRequired: "Confirm password is required",
          /////////////
          deductionTitle: "DEDUCT SALARY",
          deductionSubtitle: "Deduct Salary from Employee/Driver",
          reasonOfDeduction: "Reason of deduction",
          talabatDeductionAmountKD: "Talabat deduction amount (K.D.)",
          companyDeductionAmountKD: "Company deduction amount (K.D.)",
          submit: "Submit",
          selectUser: "Select User",
          selectDriver: "Select Driver",
          deductionReasonRequired: "Deduction reason is required",
          fileRequired: "File is required",
          fileTypeMustBePdf: "Only PDF files are allowed",
          selectedFieldsRequired: "Please select a driver or user",
          atLeastOneFieldRequired:
            "Please fill at least one of Talabat Deduction Amount or Company Deduction Amount",
          /////////////
          contactTitle: "CONTACT",
          contactSubtitle: "Contact page to contact the company team",
          /////////////
          MESSAGES: "MESSAGES",
          messagesSubtitle: "Received Messages Page",

          /////////////
          invoicesTitle: "INVOICES",
          invoices: "Invoices",
          invoicesSubtitle: "List of Invoice Balances",
          cash: "Cash",
          hours: "Hours",
          mainOrders: "Main Orders",
          additionalOrders: "Additional Orders",
          date: "Date",
          reset: "Reset",
          uploadExcel: "Upload Excel",
          downloadTemplate: "Download Template",
          excelUploadSuccess: "Invoices uploaded successfully",
          excelUploadError: "Error uploading invoices",
          /////////////
          deductionInvoicesTitle: "Deduction Invoices",
          deductionInvoicesSubtitle: "Deduction Invoices Page",
          talabatDeduction: "Talabat Deduction",
          companyDeduction: "Company Deduction",
          pettyCashDeduction: "Petty Cash Deduction",
          /////////////
          startingDate: "Starting Date",
          endingDate: "Ending Date",
          search: "Search",
          /////////////
          EmployeesSalaryTitle: "EMPLOYEES SALARY",

          EmployeesSalarySubtitle: "Employees Salary Page",
          startMonth: "Start Month",
          startYear: "Start Year",
          endMonth: "End Month",
          endYear: "End Year",
          print: "Print",
          currentLanguage: "Current Language",
          totalAmountOnCompany: "Total amount on company",
          totalAmount: "Total amount",
          pettyCashApplicantSignature: "Applicant Signature",
          pettyCashOfficeSignature: "Office Management Signature",
          pettyCashManagerSignature: "Company Manager Signature",

          AccountantSignature: "Prepared by Financial Management",
          managerSignature:
            "Company Management Approval (Administrative Manager)",
          remarks: "Remarks",
          netSalary: "Net Salary",
          notes: "NOTES",
          employeesTotalNetSalary: "Total net salary for the employees",
          kd: "KD",
          /////////////
          employeesSalary: "Employees Salary",
          driversSalary: "Drivers Salary",
          bankStatement: "Bank Statement",
          pettyCash: "Petty Cash",
          spendName: "Expense Name",
          spendTypes: "Expense Types",
          spendsType: "Expense Type",
          companySpends: "Company Expenses",
          profitsAndLosses: "Profits and Losses",
          companyIncome: "Company Income",
          /////////////
          driversSalaryTitle: "DRIVERS SALARY",
          driversSalarySubtitle: "Drivers Salary Page",

          finalSalary: "Final Salary",
          salaryAdditionalOrders: "Salary (Additional Orders)",
          salaryMainOrders: "Salary (Main Orders)",
          totalOrders: "Total Orders",
          carDriversTotalNetSalary: "Total net salary for car drivers:",
          bikeDriversTotalNetSalary: "Total net salary for bike drivers:",
          totalMonthlySalary: "Total monthly salary:",
          totalMonthlyDeduction: "Total monthly deduction:",
          totalNetSalary: "Total net salary:",
          total: "Total",
          salaryTotalOrders: "Salary from Total Orders",
          totalDeductions: "Total deductions",
          totalSummary: "Total summary",
          bikeDrivers: "Bike drivers",
          carDrivers: "Car drivers",
          /////////////
          companySpendsTitle: "COMPANY EXPENSES",
          companySpendsSubtitle: "Company Expenses Page",
          from: "From",
          to: "To",
          cashSpends: "Cash Expenses",
          deductedFrom: "Deducted From",
          deductFromUser: "Deduct From User",
          deductFromDriver: "Deduct From Driver",
          /////////////
          spendTypesTitle: "EXPENSE TYPES",
          spendTypesSubtitle: "Expense Types Page",
          addNewSpendType: "Add New Expense Type",
          /////////////
          bankStatementTitle: "BANK STATEMENT",

          bankStatementSubtitle: "Bank Statement Page",
          addNewRow: "ADD NEW ROW",
          bankAccountNumber: "Bank Account Number",
          deposits: "Deposits",
          spends: "Expenses",
          checkNumber: "Cheque Number",
          details: "Details",
          balance: "Balance",
          withdrawals: "Withdrawals",
          totalWithdrawals: "Total withdrawals",
          totalDeposits: "Total Deposits",
          currentBalance: "Current Balance",
          confirmDeleteBankStatementMessage:
            "Are you sure you want to delete this bank statement data?",
          confirmDeleteBankStatementTitle: "Confirm Delete Bank Statement Data",
          profitsAccount: "Profits Account",
          expensesAccount: "Expenses Account",
          createNewAccount: "Create New Account",
          accountCreatedSuccessfully: "Account created successfully",
          errorCreatingAccount: "Error creating account",
          pleaseEnterAllFields: "Please enter all fields",
          accountNumberIsRequired: "Account number is required",
          accountNameIsRequired: "Account name is required",
          accountNumber: "Account Number",
          accountName: "Account Name",
          accountNumberIsUnique: "Account number must be unique",
          accountNumberAlreadyExists: "Account number already exists",
          createNewBankAccount: "Create New Bank Account",
          create: "Create",
          no: "No.",
          accountNumberRequired: "Account number is required",
          accountNameRequired: "Account name is required",
          accountNumberExists: "Account number already exists",
          accountNameExists: "Account name already exists",
          /////////////
          pettyCashTitle: "PETTY CASH",
          pettyCashSubtitle: "Petty Cash Page",
          searchPettyCash: "Search Petty Cash",
          save: "Save",
          serialNumber: "Serial Number",
          requestApplicant: "Request Applicant",
          requestDate: "Request Date",
          spendsDate: "Expenses Date",
          addNewPettyCash: "Add New Petty Cash",
          selectSpendType: "Select Expense Type",
          spendsReason: "Expenses Reason",
          cashAmount: "Cash Amount",
          spendsRemarks: "Expenses Remarks",
          startingBalance: "Starting Balance",
          saveData: "Save Data",
          totalSpends: "Total Expenses",
          totalAmountOnWorkers: "Total amount on employees/drivers",
          requestApplicantRequired: "Select request applicant",
          serialNumberRequired: "Enter serial number",
          requestDateRequired: "Select request date",
          spendsDateRequired: "Select spends date",
          spendsReasonRequired: "Enter spends reason",
          cashAmountRequired: "Enter cash amount",
          spendTypeRequired: "Select spend type",
          startDateRequired: "Select start date",
          endDateRequired: "Select end date",
          endDateMustBeAfterStartDate: "End date must be after start date",

          updatePettyCashFulfilled: "Petty cash is updated successfully!",
          updatePettyCashRejected:
            "Can't update a petty cash, you can try later!",
          /////////////
          profitsAndLossesTitle: "PROFITS AND LOSSES",
          profitsAndLossesSubtitle: "Profits and Losses Page",
          addNewCompanyIncome: "Add New Company Income",
          year: "Year",
          bikeIncome: "Bike Income",
          carIncome: "Car Income",
          otherIncome: "Other Income",
          lastMonthIncome: "Last Month Income",
          lendsIncome: "Lends Income",
          moneySafeBalance: "Money Safe Balance",
          refundCompany: "Refund Company",
          refundAmount: "Refund Amount",
          selectIncomeType: "Select Income type",
          selectMonth: "Select Month",
          income: "Income",
          refund: "Refund",
          talabatCarIncome: "Talabat Car Income",
          talabatBikeIncome: "Talabat Bike Income",
          talabatOtherIncome: "Talabat Other Income",
          netProfitLosses: "Net Profit/Losses",
          netProfitLossesAfterRefunds: "Net Profit/Losses After Refunds",
          totalRefunds: "Total Refunds",
          // Month translations
          Jan: "January",
          Feb: "February",
          Mar: "March",
          Apr: "April",
          May: "May",
          Jun: "June",
          Jul: "July",
          Aug: "August",
          Sep: "September",
          Oct: "October",
          Nov: "November",
          Dec: "December",
          /////////////
          userDeletedSuccessfully: "User is successfully deleted!",
          userDeletedFailed: "Can't delete a user, you can try later!",
          userInformationUpdated: "User Information is updated successfully.",
          userInformationUdateFailed: "Something went wrong! Please try later.",
          /////////////

          totalCash: "Total Cash",
          totalHours: "Total Hours",
          revenue: "Revenue",
          revenueGenerated: "Revenue Generated",
          salesQuantity: "Sales Quantity",
          /////////////
          deactivatedDrivers: "Deactivated Drivers",
          deactivatedDriversTitle: "DEACTIVATED DRIVERS",
          deactivatedDriversSubtitle: "Deactivated Drivers Page",
          deactivate: "Deactivate",
          reactivate: "Reactivate",
          deactivationReason: "Deactivation Reason",
          /////////////
          deactivatedDriverFulfilled:
            "Driver has been deactivate successfully!",
          deactivatedDriverrejected:
            "Can't deactivate a driver, you can try later!",
          activatedDriverFulfilled: "Driver has been activated successfully!",
          activatedDriverRejected:
            "Can't activate a driver, you can try later!",
          overrideDriverSalaryFulfilled:
            "Driver's information is successfully updated!",
          overrideDriverSalaryRejected:
            "Can't update a driver's information, you can try later!",
          /////////////
          updatedDriverFulfilled:
            "Driver's information is successfully updated!",
          updatedDriverRejected:
            "Can't update a driver's information, you can try later!",
          registerDriverFulfilled: "New driver is added successfully!",
          registerDriverRejected: "Can't add a driver, you can try later!",
          deleteDriverFulfilled: "Driver is successfully deleted!",
          deleteDriverRejected: "Can't delete a driver, you can try later!",
          /////////////
          changeLanguage: "Change Language",
          darkMode: "Dark Mode",
          lightMode: "Light Mode",
          noMessages: "No messages available",
          /////////////
          sendMessageFulfilled: "Message is successfully sent!",
          sendMessageRejected: "Can't send a message, you can try later!",
          updateAdditionalSalaryFulfilled: "User data is updated successfully.",
          updateAdditionalSalaryRejected:
            "Something went wrong! Please try later.",
          updateUserRejected: "Something went wrong! Please try later.",
          updateUserFulfilled: "User Information is updated successfully.",
          deleteUserRejected: "Can't delete a user, you can try later!",
          deleteUserFulfilled: "User is successfully deleted!",
          /////////////
          registerUserFulfilled: "User is added successfully!",
          registerUserRejected: "Something went wrong! Please try later!",
          profileImageFulfilled: "User profile image is uploaded successfully!",
          profileImageRejected: "Something went wrong! Please try later!",
          createUserInvoiceFulfilled: "Employee invoice is added successfully!",
          createUserInvoiceRejected: "Something went wrong! Please try later.",
          /////////////
          addSpendTypeFulfilled: "Expense type is successfully added!",
          addSpendTypeRejected: "Can't add an expense type, you can try later!",
          deleteSpendTypeFulfilled: "Expense type is successfully deleted!",
          deleteSpendTypeRejected:
            "Can't delete an expense type, you can try later!",
          /////////////
          createPettyCashFulfilled: "Petty cash is successfully added!",
          createPettyCashRejected: "Can't add a petty cash, you can try later!",
          /////////////
          createCompanyIncomeFulfilled: "Income is successfully added!",
          createCompanyIncomeRejected:
            "Can't add an income, you can try later!",
          /////////////
          addCompanyFilesFulfilled: "Company file is successfully added!",
          saveCompanyFilesSuccess: "Company file is successfully saved!",
          addCompanyFilesRejected:
            "Can't add a company file, you can try later!",
          deleteCompanyFileFulfilled: "Company file is successfully deleted!",
          deleteCompanyFileRejected:
            "Can't delete a company file, you can try later!",
          /////////////
          createBankStatementFulfilled: "Bank statement is successfully added!",
          createBankStatementRejected:
            "Can't add a bank statement, you can try later!",
          fetchBankAccountsRejected:
            "Failed to fetch bank accounts, please try later!",
          /////////////
          createDriverInvoiceFulfilled: "Driver invoice is added successfully!",
          createDriverInvoiceRejected:
            "Can't add a driver invoice, you can try later!",
          updateDriverInvoiceFulfilled:
            "Driver invoice is updated successfully!",
          updateDriverInvoiceRejected:
            "Can't update a driver invoice, you can try later!",
          updateEmployeeInvoiceFulfilled:
            "User invoice is updated successfully!",
          updateEmployeeInvoiceRejected:
            "Can't update a user invoice, you can try later!",
          resetDriverInvoicesFulfilled: "Invoices are reset successfully!",
          resetDriverInvoicesRejected:
            "Can't reset invoices, you can try later!",
          resetSingleDriverInvoiceFulfilled:
            "Driver's Invoices are reset successfully!",
          resetSingleDriverInvoiceRejected:
            "Can't reset driver's invoices, you can try later!",
          updateInvoiceDetailsFulfilled:
            "Invoice details are updated successfully!",
          updateInvoiceDetailsRejected: "Failed to update invoice details",
          resetInvoicesFulfilled: "Invoices have been reset successfully",
          resetInvoicesRejected: "Failed to reset invoices",
          invoicesResetByOtherUser: "Invoices have been reset by another user",

          resetInvoicesHeading: "إعادة تعيين الفواتير",
          resetInvoicesMessage:
            "بإعادة تعيين جميع الفواتير {{senderName}} ({{senderRole}}) قام",
          restoreInvoicesHeading: "استرجاع الفواتير",
          restoreInvoicesMessage:
            "باسترجاع الفواتير المؤرشفة {{senderName}} ({{senderRole}}) قام",

          // Add these new translations
          deductionAddHeading: "{{targetName}} Deduction Alert!",
          deductionAddMessage:
            "{{senderName}} has made a deduction request on {{date}}",

          deductionApproveHeading: "{{targetName}} Deduction Alert",
          deductionApproveMessage:
            "{{senderName}} has approved deduction request on {{date}}",

          deductionRejectHeading: "{{targetName}} Deduction Alert",
          deductionRejectMessage:
            "{{senderName}} has rejected deduction request on {{date}}",

          deactivationHeading: "{{targetName}} Deactivation Alert",
          deactivationMessage:
            "{{senderName}} has deactivated driver on {{date}}",

          activationHeading: "{{targetName}} Activation Alert",
          activationMessage: "{{senderName}} has activated driver on {{date}}",

          newMessageHeading: "New Message Alert",
          newMessageMessage:
            "{{senderName}} has sent you a message on {{date}}",

          civilIdExpiryHeading: "{{targetName}} Civil Id Expiration Alert",
          civilIdExpiryMessage: "Civil id will expire on {{date}}",

          passportExpiryHeading: "{{targetName}} Passport Expiration Alert",
          passportExpiryMessage: "Passport will expire on {{date}}",

          healthInsuranceExpiryHeading:
            "{{targetName}} Health Insurance Expiration Alert",
          healthInsuranceExpiryMessage:
            "Health Insurance will expire on {{date}}",

          contractExpiryHeading: "{{targetName}} Contract Expiration Alert",
          contractExpiryMessage: "Contract will expire on {{date}}",
          /////////////
          archiveFormTitle: "ARCHIVE FORM",
          archiveFormSubtitle: "Add a new file to the archive",
          fullName: "Full Name",
          company: "Company",
          workNumber: "Working Number",
          archiveNumber: "Archive Number",
          fullNameIsRequired: "Full name is required",
          companyIsRequired: "company name is required",
          idNumberIsRequired: "ID number is required",
          vehicleIsRequired: "Vehicle type is required",
          workNumberIsRequired: "Working number is required",
          archiveNumberIsRequired: "Archive number is required",
          addToArchive: "Add to archive",
          archiveForm: "Achive Form",
          searchArchiveTitle: "SEARCH ARCHIVE",
          searchArchiveSubtitle: "Search files in the archive",
          addArchiveFulfilled: "Archive is added successfully",
          addArchiveRejected: "Can't add an archive, please try later",
          deleteArchiveFulfilled: "Archive is deleted successfully",
          deleteArchiveRejected: "Can't delete archive, please try later",
          noFileAvailable: "No file available for this archive",
          backToMainSystem: "Back to system",
          totalOrdersThisMonth: "Total Orders This Month",
          totalCashThisMonth: "Total Cash This Month",
          totalHoursThisMonth: "Total Hours This Month",
          updateArchiveFulfilled: "Archive is updated successfully",
          updateArchiveRejected: "Can't update archive, please try later",
          updateFile: "Update File",
          selectName: "Select Name",
          updateError: "Something went wrong! Please try later.",
          fileUpdateError: "Something went wrong! Please try later.",
          /////////////
          deliveryManagement: "Delivery Management System",
          landingPageHero:
            "Streamline your delivery operations with our comprehensive management system. Track drivers, manage orders, and optimize routes in real-time.",
          getStarted: "Get Started",
          keyFeatures: "Key Features",
          efficientDelivery: "Efficient Delivery",
          efficientDeliveryDesc:
            "Optimize routes and manage deliveries efficiently with our advanced delivery management system.",
          realTimeTracking: "Real-Time Tracking",
          realTimeTrackingDesc:
            "Track your drivers and deliveries in real-time with precise location updates and status monitoring.",
          driverManagement: "Driver Management",
          driverManagementDesc:
            "Comprehensive driver management with profiles, schedules, and performance tracking.",
          readyToStart: "Ready to optimize your delivery operations?",
          startNow: "Start Now",
          invoiceManagement: "Invoice Management",
          invoiceManagementDesc:
            "Efficiently manage and track all delivery invoices, payments, and financial records in one centralized system.",
          uploadExcel: "Upload Excel",
          createArchivedDriverInvoiceRejected:
            "Can't add a driver invoice, you can try later!",
          createArchivedDriverInvoiceFulfilled:
            "Driver invoice is added successfully!",
          confirmResetInvoices: "Are you sure you want to reset all invoices?",
          confirmResetMessage:
            "This action will reset all invoices and cannot be undone.",
          confirmReset: "Confirm Reset",
          confirmDeleteMessage: "Are you sure you want to delete this file?",
          confirmDelete: "Confirm Delete",
          confirm: "Confirm",
          confirmRejectDeduction:
            "Are you sure you want to reject this deduction?",
          confirmApproveDeduction:
            "Are you sure you want to approve this deduction?",
          confirmDeactivate: "Are you sure you want to deactivate this driver?",
          confirmDeactivateMessage:
            "This action will deactivate the driver and cannot be undone.",
          confirmDeleteDriver: "Are you sure you want to delete this driver?",
          confirmDeleteDriverMessage:
            "This action will delete the driver and cannot be undone.",
          confirmReactivateMessage:
            "Are you sure you want to reactivate this driver?",
          confirmReactivate: "Confirm Reactivate",

          // Driver Schema Validations
          phoneAlreadyExists: "Phone number already exists in the system.",
          firstNameRequired: "First name is required",
          lastNameRequired: "Last name is required",
          phoneRequired: "Phone number is required",
          idNumberRequired: "ID number is required",
          idExpiryDateRequired: "ID expiry date is required",
          passportNumberRequired: "Passport number is required",
          passportExpiryDateRequired: "Passport expiry date is required",
          contractExpiryDateRequired: "Contract expiry date is required",
          driverLicenseExpiryDateRequired:
            "Driver license expiry date is required",
          carPlateNumberRequired: "Car plate number is required",
          carRegisterationExpiryDateRequired:
            "Car registration expiry date is required",
          carRegisterationRequired: "Car registration is required",
          gasCardRequired: "Gas card is required",
          healthInsuranceExpiryDateRequired:
            "Health insurance expiry date is required",
          employeeCompanyNumberRequired: "Employee company number is required",
          ibanRequired: "IBAN is required",
          bankNameRequired: "Bank name is required",
          positionRequired: "Position is required",
          vehicleRequired: "Vehicle type is required",
          contractTypeRequired: "Contract type is required",
          talabatIdRequired: "Talabat ID is required",
          mainSalaryRequired: "Main salary is required",
          passportAlreadyExists:
            "Passport number already exists in the system.",
          identificationAlreadyExists:
            "Identification number already exists in the system.",
          emailAlreadyExists: "Email already exists in the system.",
          passwordsNotMatch: "Passwords do not match",
          restore: "Restore",
          confirmRestore: "Are you sure you want to restore these invoices?",
          restoreInvoicesFulfilled: "Invoices are restored successfully!",
          restoreInvoicesRejected: "Can't restore invoices, you can try later!",
          mainOrder: "Main Order",
          additionalOrder: "Additional Order",
          hour: "Hour",
          close: "Close",
          clear: "Clear",
          initialize: "Initialize",
          excelColumnNote: "Excel file must have a 'spendType' column",
          /////////////////
          salaryReportTitle: "Salary Report",
          salaryReportSubtitle: "Salary report for all employees and drivers",
          salaryReport: "Salary Report",
          totalSalary: "Total Salary",
          bankName: "Bank Name",
          position: "Position",
          salary: "Salary",
          invalidPosition: "Position is not valid",
          Engineer: "Engineer",
          Administrative: "Administrative",
          CEO: "CEO",
          COO: "COO",
          CFO: "CFO",
          CTO: "CTO",
          MarketingManager: "Marketing Manager",
          SalesManager: "Sales Manager",
          HRManager: "HR Manager",
          ProjectManager: "Project Manager",
          ProductManager: "Product Manager",
          BusinessAnalyst: "Business Analyst",
          SoftwareEngineer: "Software Engineer",
          WebDeveloper: "Web Developer",
          GraphicDesigner: "Graphic Designer",
          ContentWriter: "Content Writer",
          CustomerSupportRepresentative: "Customer Support Representative",
          DataAnalyst: "Data Analyst",
          OperationsManager: "Operations Manager",
          AdminAssistant: "Admin Assistant",
          TeamLeader: "Team Leader",
          MarketingSpecialist: "Marketing Specialist",
          LegalAdvisor: "Legal Advisor",
          ITSupportSpecialist: "IT Support Specialist",
          Receptionist: "Receptionist",
          Intern: "Intern",
          Representative: "Representative",
          download: "Download",
          startDay: "From Day",
          endDay: "To Day",
          /////////////////
          "National Bank of Kuwait (NBK)": "National Bank of Kuwait (NBK)",
          "Kuwait Finance House (KFH)": "Kuwait Finance House (KFH)",
          "Burgan Bank": "Burgan Bank",
          "Gulf Bank": "Gulf Bank",
          "Commercial Bank of Kuwait": "Commercial Bank of Kuwait",
          "Al Ahli Bank of Kuwait": "Al Ahli Bank of Kuwait",
          "Boubyan Bank": "Boubyan Bank",
          "Kuwait International Bank": "Kuwait International Bank",
          "Warba Bank": "Warba Bank",
          "Industrial Bank of Kuwait": "Industrial Bank of Kuwait",
          /////////////////
          salaryConfigurationTitle: "Salary Configuration",
          salaryConfigurationSubtitle:
            "Configure the salary for the employees and drivers",
          salaryConfig: "Salary Configuration",
          minimumOrders: "Minimum Orders",
          maximumOrders: "Maximum Orders",
          multiplier: "Multiplier",
          fixedAmount: "Fixed Amount",
          saveChanges: "Save Changes",
          applyToMainOrdersOnly: "Apply to Main Orders Only",
          addRule: "Add Rule",
          noRulesExist: "No rules exist",
          invalidNumbersInRules:
            "Invalid numbers found in rules. Please check all values are valid numbers.",
          overlapError:
            "Overlap found between rules: {{rule1}} and {{rule2}}. Rules cannot overlap.",
          gapError:
            "Gap found between {{rule1}} and {{rule2}}. Rules must be continuous.",
          validationSuccess: "Rules validation successful!",
          createSalaryConfigFulfilled: "Rules are created successfully!",
          createSalaryConfigRejected: "Can't create rules, you can try later!",
          updateSalaryConfigFulfilled: "Rules are updated successfully!",
          updateSalaryConfigRejected: "Can't update rules, you can try later!",
          deleteSalaryConfigFulfilled: "Rules are deleted successfully!",
          deleteSalaryConfigRejected: "Can't delete rules, you can try later!",
          ////////////
          driversSalaryDetailsTitle: "Monthly Salary Details",
          driversSalaryDetailsSubtitle:
            "Monthly salary details for all employees and drivers",
          driversSalaryDetails: "Monthly Salary Details",
          cashSalary: "Cash Payment",
          summary: "Summary",
          netSalaryAfterDeductions: "Bank Transfer",
          specialBikeDriverSummary: "Hakikat Hameed Bike Driver Summary",
          allBikeDriversSummary: "All Bike Drivers Summary",
          allDriversSummary: "All Drivers Summary (Cars + Bikes)",
          finalSummary: "Final Summary",
          totalDriversNetSalary: "Total Drivers Net Salary",
          totalDriversCashPayment: "Total Drivers Cash Payment",
          totalBankTransfer: "Total Bank Transfer",
          grandTotal: "Grand Total",
          deductions: "Deductions",
          cashPayment: "Cash Payment",
          allEmployeesSummary: "All Administrators Summary",
          totalBankTransferAllStaff: "Total Bank Transfer All Staff",
          totalCashPaymentAllStaff: "Total Cash Payment All Staff",
          allEmployeesAndDriversSummary: "All Employees and Drivers Summary",
        },
      },
      ar: {
        translation: {
          welcome: "مرحبا",
          pages: "الصفحات",
          dashboard: "لوحة القيادة",
          DASHBOARD: "لوحة القيادة",
          dashboardSubtitle: "مرحبًا بك في لوحة القيادة الخاصة بك",
          TEAM: "الفريق",
          DRIVERS: "السائقين",
          driverFormTitle: "إضافة سائق",
          driverFormSubtitle: "إضافة سائق جديد",
          NOTIFICATIONS: "اشعارات",
          notificationTitle: "صفحة الاشعارات الهامة",
          deductionSalaryTitle: "خصم الراتب",
          manageTeamMembers: "إدارة أعضاء الفريق",
          manageDriverMembers: "إدارة السائقين",
          deductionSalaryRequest: "صفحة طلبات خصم الراتب",
          manageTeam: "إدارة الفريق",
          manageDrivers: "إدارة السائقين",
          userProfileTitle: "الملف التعريفي للموظف",
          userProfileSubtitle: "عرض/تحديث معلومات الموظف",
          deductionInvoices: "فواتير الخصم",
          driversInvoices: "فواتير السائقين",
          notifications: "الإشعارات",
          settings: "الإعدادات",
          forms: "نماذج",
          salaries: "الرواتب",
          contactAndMessages: "التواصل والرسائل",
          employeesManagement: "إدارة الموظفين",
          accountingPages: "صفحات المحاسبة",
          archive: "الأرشيف",
          profileForm: "نموذج الفريق",
          driversForm: "نموذج السائقين",
          deduction: "خصم",
          deductionForm: "نموذج الخصم",
          contact: "التواصل",
          messages: "الرسائل",
          receivedMessages: "الرسائل المستلمة",
          sentMessages: "الرسائل المرسلة",
          noSearchResults: "لا يوجد نتائج للبحث",
          attachFile: "إرفاق ملف",
          noFileAvailable: "لا يوجد ملف مرفوع",
          viewFile: "عرض الملف",
          title: "العنوان",
          sender: "المرسل",
          date: "التاريخ",
          actions: "الإجراءات",
          subject: "الموضوع",
          searchMessagesBySubject: "ابحث عن رسالة بالموضوع",
          invoicesArchive: "أرشيف الفواتير",
          invoicesArchiveSubtitle: "قائمة الفواتير المؤرشفة",
          invoiceDetails: "تفاصيل الفاتورة",
          invoiceDetailsByDate: "تفاصيل الفاتورة لتاريخ",
          message: "رسالة",
          send: "ارسال",
          unknownSender: "مرسل غير معروف",
          companyFiles: "ملفات الشركة",
          searchingArchive: "البحث في الأرشيف",
          companyFilesSubtitle: "صفحة ملفات الشركة",
          data: "بيانات",
          users: "المستخدمين",
          role: "دور المستخدم في النظام",
          admins: "المدراء التنفيذيون",
          managers: "المدراء",
          accountants: "المحاسبون",
          employees: "الموظفين",
          Admin: "مدير تنفيذي",
          Manager: "مدير",
          Accountant: "محاسب",
          Employee: "موظف",
          driver: "السائق",
          drivers: "السائقين",
          login: "تسجيل الدخول",
          logout: "تسجيل الخروج",
          loggingIn: "...جاري تسجيل الدخول",
          noEditPermission: "ليس لديك صلاحية لتعديل هذا الحقل",
          invalidCredentials: "البريد الإلكتروني أو كلمة المرور غير صالحة",
          userNotFound: "المستخدم غير موجود",
          networkError: "حدث خطأ في الاتصال بالخادم",
          generalError: "حدث خطأ عند تسجيل الدخول",
          /////////////
          email: "البريد الإلكتروني",
          password: "كلمة المرور",
          confirmPassword: "تأكيد كلمة المرور",
          createNewUser: "إنشاء مستخدم جديد",
          phone: "رقم الهاتف",
          name: "الاسم",
          civilId: "رقم الهوية",
          passport: "رقم الجواز",
          mainSalary: "الراتب الأساسي",
          accessLevel: "مستوى الوصول",
          actions: "الاجراءات",
          deductionReject: {
            Heading: "تم رفض الخصم",
            Message: "تم رفض طلب الخصم",
          },
          "deductionReject Heading": "خصم راتب - {{targetName}}",
          "deductionReject Message":
            "تم رفض طلب الخصم من {{senderName}} في {{date}}",

          deductionApprove: "تمت الموافقة على الخصم",
          Heading: "العنوان",
          Message: "الرسالة",
          deductionRejectHeading: "خصم راتب الموظف - {{targetName}}",
          deductionRejectMessage:
            "{{senderName}} تم رفض طلب الخصم المطلوب من قبل {{date}}",
          /////////////
          required: "مطلوب",
          firstName: "الاسم الأول",
          lastName: "الاسم الأخير",
          optionalEmail: "البريد الإلكتروني (اختياري)",
          idNumber: "رقم الهوية",
          idExpiryDate: "تاريخ انتهاء الهوية",
          passportExpiryDate: "تاريخ انتهاء الجواز",
          contractExpiryDate: "تاريخ انتهاء العقد",
          driverLicenseExpiryDate: "تاريخ انتهاء رخصة القيادة",
          carPlateNumber: "رقم لوحة السيارة",
          carRegisteration: "تسجيل السيارة",
          carRegisterationExpiryDate: "تاريخ انتهاء تسجيل السيارة",
          workPass: "تصريح العمل",
          gasCard: "بطاقة الوقود",
          healthInsuranceExpiryDate: "تاريخ انتهاء التأمين الصحي",
          healthInsurance: "التأمين الصحي",
          carType: "نوع السيارة",
          employeeCompanyNumber: "رقم موظف الشركة",
          iban: "IBAN",
          vehicle: "المركبة",
          car: "سيارة",
          bike: "دراجة",
          Car: "سيارة",
          Bike: "دراجة",
          contractType: "نوع العقد",
          talabat: "طلبات",
          others: "أخرى",
          talabatId: "رقم هوية طلبات",
          uploadFile: "تحميل الملف",
          removeImage: "إزالة الصورة",
          changeImage: "تغيير الصورة",
          uploadImage: "رفع الصورة",
          invalidFileSelected: "الملف غير صالح، يجب أن يكون صورة",
          profileImageRemoved: "تم إزالة الصورة الشخصية بنجاح",
          profileImageRemovalFailed:
            "لم يتم إزالة الصورة الشخصية، يمكنك المحاولة لاحقًا",
          viewUploadedFile: "عرض الملف المحمل",
          update: "تحديث",
          delete: "حذف",
          confirmDeletePettyCashTitle: "حذف العهدة النقدية",
          confirmDeletePettyCashMessage:
            "هل أنت متأكد من رغبتك في حذف هذه العهدة النقدية؟",
          cancel: "إلغاء",
          deletePettyCashFulfilled: "تم حذف العهدة النقدية بنجاح",
          deletePettyCashRejected:
            "لا يمكن حذف العهدة النقدية، يمكنك المحاولة لاحقًا",
          add: "إضافة",
          edit: "تعديل",
          addNewDriver: "إضافة سائق جديد",
          /////////////

          additionalSalary: "الراتب الإضافي",
          talabatDeductionAmount: "خصم طلبات",
          companyDeductionAmount: "خصم الشركة",
          pettyCashDeductionAmount: "خصم المصروفات الصغيرة",
          deductionReason: "سبب الخصم",
          deductionDate: "تاريخ الخصم",
          deductionDateRequired: "تاريخ الخصم مطلوب",
          preview: "معاينة",
          uploadNewFile: "ارفع ملف جديد",
          /////////////
          willExpireOn: "سوف ينتهي في",
          expirationAlert: "تنبيه انتهاء صلاحية",
          /////////////
          createUserTitle: "إنشاء حساب مستخدم",
          createUserSubtitle: "إنشاء ملف تعريف مستخدم جديد",
          /////////////
          firstNameIsRequired: "الاسم الأول مطلوب",
          lastNameIsRequired: "الاسم الأخير مطلوب",
          invalidEmail: "البريد الإلكتروني غير صحيح",
          emailIsRequired: "البريد الإلكتروني مطلوب",
          invalidPhoneNumber: "رقم الهاتف غير صحيح",
          phoneIsRequired: "رقم الهاتف مطلوب",
          identificationIsRequired: "رقم الهوية مطلوب",
          passportIsRequired: "رقم جواز السفر مطلوب",
          contractExpiryDateIsRequired: "تاريخ انتهاء العقد مطلوب",
          roleIsRequired: "يجب اختيار الدور",
          mainSalaryIsRequired: "الراتب الأساسي مطلوب",
          passwordMinLength: "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل",
          passwordIsRequired: "كلمة المرور مطلوبة",
          passwordMustMatch: "يجب أن تتطابق كلمتا المرور",
          confirmPasswordIsRequired: "تأكيد كلمة المرور مطلوبة",
          /////////////
          deductionTitle: "خصم الراتب",
          deductionSubtitle: "خصم الراتب من الموظف/السائق",
          reasonOfDeduction: "سبب الخصم",
          talabatDeductionAmountKD: "قيمة الخصم من طلبات (د.ك.)",
          companyDeductionAmountKD: "قيمة الخصم من الشركة (د.ك.)",
          submit: "تقديم",
          selectUser: "اختر الموظف",
          selectDriver: "اختر السائق",
          deductionReasonRequired: "سبب الخصم مطلوب",
          fileRequired: "الملف مطلوب",
          fileTypeMustBePdf: "يجب أن يكون الملف بصيغة PDF",
          selectedFieldsRequired: "يجب اختيار سائق أو موظف",
          atLeastOneFieldRequired: "يجب ملء إحدى قيمتي خصم طلبات أو خصم الشركة",
          /////////////
          contactTitle: "التواصل",
          contactSubtitle: "صفحة الاتصال للتواصل مع فريق الشركة",

          /////////////
          MESSAGES: "الرسائل",
          messagesSubtitle: "صفحة الرسائل المستلمة",
          /////////////
          invoicesTitle: "الفواتير",
          invoices: "الفواتير",
          invoicesSubtitle: "قائمة أرصدة الفواتير",
          cash: "المبلغ",
          hours: "ساعات",
          mainOrders: "الطلبات الرئيسية",
          additionalOrders: "الطلبات الإضافية",
          date: "التاريخ",
          reset: "إعادة تعيين",
          uploadExcel: "رفع Excel",
          downloadTemplate: "تحميل القالب",
          excelUploadSuccess: "تم رفع الفواتير بنجاح",
          excelUploadError: "حدث خطأ ما! يرجى المحاولة لاحقًا",
          /////////////
          deductionInvoicesTitle: "فواتير الخصم",
          deductionInvoicesSubtitle: "صفحة فواتير الخصم",
          talabatDeduction: "خصم طلبات",
          companyDeduction: "خصم الشركة",
          pettyCashDeduction: "خصم العهدة النقدية",
          /////////////

          startingDate: "تاريخ البدء",
          endingDate: "تاريخ الانتهاء",
          search: "ابحث",

          /////////////
          EmployeesSalaryTitle: "رواتب الموظفين",
          EmployeesSalarySubtitle: "صفحة رواتب الموظفين",
          startMonth: "من شهر",
          startYear: "من سنة",
          endMonth: "إلى شهر",
          endYear: "إلى سنة",
          print: "طباعة",
          currentLanguage: "اللغة الحالية",
          totalAmountOnCompany: "إجمالي المبلغ على الشركة",
          totalAmount: "إجمالي المبلغ",
          AccountantSignature: "اعداد الإدارة المالية",
          managerSignature: "اعتماد إدارة الشركة (المدير الإداري)",
          pettyCashApplicantSignature: "توقيع مقدم العهدة",
          pettyCashOfficeSignature: "توقيع إدارة المكتب",
          pettyCashManagerSignature: "توقيع مدير الشركة",
          remarks: "ملاحظات",
          netSalary: "صافي الراتب",
          notes: "ملحوظات",
          employeesTotalNetSalary: "إجمالي صافي الراتب للموظفين ",
          kd: "دك",
          /////////////
          employeesSalary: "راواتب الموظفين",
          driversSalary: "رواتب السائقين",
          bankStatement: "كشف الحساب البنكي",
          pettyCash: "العهدة النقدية",
          spendName: "اسم العهدة",
          spendTypes: "أنواع العهد النقدية",
          spendType: "نوع العهدة النقدية",
          companySpends: "مصاريف الشركة",
          profitsAndLosses: "الأرباح والخسائر",
          companyIncome: "دخل الشركة",
          /////////////
          driversSalaryTitle: "رواتب السائقين",
          driversSalarySubtitle: "صفحة رواتب السائقين",
          finalSalary: "الراتب النهائي",
          salaryAdditionalOrders: "الراتب (الطلبات الإضافية)",
          salaryMainOrders: "الراتب (الطلبات الرئيسية)",
          totalOrders: "إجمالي الطلبات",
          carDriversTotalNetSalary: "إجمالي الراتب الصافي لسائقي السيارات:",
          bikeDriversTotalNetSalary: "إجمالي الراتب الصافي لسائقي الدراجات:",
          totalMonthlySalary: "إجمالي الراتب الشهري:",
          totalMonthlyDeduction: "إجمالي الخصم الشهري:",
          totalNetSalary: "إجمالي الراتب الصافي:",
          total: "إجمالي",
          salaryTotalOrders: "الراتب من إجمالي الطلبات",
          bikeDrivers: "سائقون الدراجات",
          carDrivers: "سائقون السيارات",
          totalDeductions: "إجمالي الخصومات",
          totalSummary: "إجمالي الملخص",
          /////////////
          companySpendsTitle: "مصاريف الشركة",
          companySpendsSubtitle: "صفحة مصاريف الشركة",
          from: "من",
          to: "إلى",
          cashSpends: "المصاريف النقدية",
          deductedFrom: "مخصومة من",
          deductFromUser: "خصم من الموظف",
          deductFromDriver: "خصم من السائق",
          /////////////
          spendTypesTitle: "أنواع المصاريف",
          spendTypesSubtitle: "صفحة أنواع المصاريف",
          addNewSpendType: "إضافة نوع مصروف جديد",
          /////////////
          bankStatementTitle: "كشف الحساب البنكي",
          bankStatementSubtitle: "صفحة كشف الحساب البنكي",
          addNewRow: "إضافة صف جديد",
          bankAccountNumber: "رقم الحساب البنكي",
          deposits: "الودائع",
          spends: "المصروفات",
          checkNumber: "رقم الشيك",
          details: "التفاصيل",
          balance: "الرصيد",
          withdrawals: "المسحوبات",
          totalWithdrawals: "إجمالي المسحوبات",
          totalDeposits: "إجمالي الودائع",
          currentBalance: "الرصيد الحالي",
          confirmDeleteBankStatementMessage:
            "هل أنت متاكد من حذف البيانات من الكشف البنكي؟",
          confirmDeleteBankStatementTitle: "تأكيد حذف البيانات من الكشف البنكي",
          profitsAccount: "حساب الأرباح",
          expensesAccount: "حساب المصاريف",
          createNewAccount: "إنشاء حساب جديد",
          accountCreatedSuccessfully: "تم إنشاء الحساب بنجاح",
          errorCreatingAccount: "حدث خطأ ما! يرجى المحاولة لاحقًا",
          accountNumber: "رقم الحساب",
          accountName: "اسم الحساب",
          pleaseEnterAllFields: "يرجى إدخال جميع الحقول",
          createNewBankAccount: "إنشاء حساب بنكي جديد",
          create: "إنشاء",
          no: "الرقم",
          accountNumberRequired: "رقم الحساب مطلوب",
          accountNameRequired: "اسم الحساب مطلوب",
          accountNumberExists: "رقم الحساب موجود بالفعل",
          accountNameExists: "اسم الحساب موجود بالفعل",
          /////////////
          pettyCashTitle: "العهدة النقدية",
          pettyCashSubtitle: "صفحة العهدة النقدية",
          searchPettyCash: "بحث عن العهد النقدية",
          save: "حفظ",
          serialNumber: "الرقم التسلسلي",
          requestApplicant: "مقدم الطلب",
          requestDate: "تاريخ الطلب",
          spendsDate: "تاريخ المصروفات",
          addNewPettyCash: "إضافة عهدة نقدية جديدة",
          selectSpendType: "اختر نوع العهدة النقدية",
          spendsReason: "سبب العهدة النقدية",
          cashAmount: "مبلغ العهدة النقدية",
          spendsRemarks: "ملاحظات المصروفات",
          startingBalance: "الرصيد الافتتاحي",
          saveData: "حفظ البيانات",
          totalSpends: "إجمالي المصروفات",
          totalAmountOnWorkers: "إجمالي المبلغ على الموظفين/السائقين",
          requestApplicantRequired: "اختر مقدم الطلب",
          serialNumberRequired: "ادخل الرقم التسلسلي",
          requestDateRequired: "اختر تاريخ الطلب",
          spendsDateRequired: "اختر تاريخ المصروفات",
          spendsReasonRequired: "ادخل سبب المصروفات",
          cashAmountRequired: "ادخل مبلغ العهدة النقدية",
          spendTypeRequired: "اختر نوع المصروف",
          startDateRequired: "اختر تاريخ البدء",
          endDateRequired: "اختر تاريخ الانتهاء",
          endDateMustBeAfterStartDate:
            "يجب أن يكون تاريخ الانتهاء بعد تاريخ البدء",

          updatePettyCashFulfilled: "تم تحديث العهدة النقدية بنجاح",
          updatePettyCashRejected:
            "لا يمكن تحديث العهدة النقدية، يمكنك المحاولة لاحقًا",
          /////////////
          profitsAndLossesTitle: "الأرباح والخسائر",
          profitsAndLossesSubtitle: "صفحة الأرباح والخسائر",
          addNewCompanyIncome: "إضافة دخل جديد للشركة",
          year: "سنة",
          bikeIncome: "دخل الدراجات",
          carIncome: "دخل السيارات",
          otherIncome: "دخل آخر",
          lastMonthIncome: "دخل الشهر الماضي",
          lendsIncome: "دخل القروض",
          moneySafeBalance: "رصيد صندوق المال",
          refundCompany: "استرداد الشركة",
          refundAmount: "مبلغ الاسترداد",
          selectIncomeType: "اختر نوع الدخل",
          selectMonth: "اختر الشهر",
          income: "الدخل",
          refund: "الاسترداد",
          talabatCarIncome: "دخل سيارات طلبات",
          talabatBikeIncome: "دخل دراجات طلبات",
          talabatOtherIncome: "دخل آخر من طلبات",
          netProfitLosses: "أرباح وخسائر صافية",
          netProfitLossesAfterRefunds: "أرباح وخسائر صافية بعد الاستردادات",
          totalRefunds: "إجمالي الاستردادات",
          //////////////
          Jan: "يناير",
          Feb: "فبراير",
          Mar: "مارس",
          Apr: "أبريل",
          May: "مايو",

          Jun: "يونيو",
          Jul: "يوليو",
          Aug: "أغسطس",
          Sep: "سبتمبر",
          Oct: "أكتوبر",
          Nov: "نوفمبر",
          Dec: "ديسمبر",
          /////////////
          totalCash: "إجمالي النقود",
          totalHours: "إجمالي الساعات",
          revenue: "الإيرادات",

          revenueGenerated: "الإيرادات المتولدة",
          salesQuantity: "كمية المبيعات",
          /////////////
          deactivatedDrivers: "سائقين معطلون",
          deactivatedDriversTitle: "السائقون المعطلون",
          deactivatedDriversSubtitle: "صفحة السائقون المعطلون",
          deactivate: "إيقاف التفعيل",
          reactivate: "إعادة تفعيل",
          deactivationReason: "سبب التعطيل",
          deactivatedDriverFulfilled: "تم إيقاف تفعيل السائق بنجاح",
          deactivatedDriverrejected:
            "لا يمكن تعطيل السائق، يمكنك المحاولة لاحقًا",
          activatedDriverFulfilled: "تم تفعيل السائق بنجاح",
          activatedDriverRejected:
            "لا يمكن تفعيل السائق، يمكنك المحاولة لاحقًا",
          overrideDriverSalaryFulfilled: "تم تحديث معلومات السائق بنجاح",
          overrideDriverSalaryRejected:
            "لا يمكن تحديث معلومات السائق، يمكنك المحاولة لاحقًا",

          /////////////
          updatedDriverFulfilled: "تم تحديث معلومات السائق بنجاح",
          updatedDriverRejected:
            "لا يمكن تحديث معلومات السائق، يمكنك المحاولة لاحقًا",
          registerDriverFulfilled: "تمت إضافة السائق الجديد بنجاح",
          registerDriverRejected:
            "لا يمكن إضافة سائق جديد، يمكنك المحاولة لاحقًا",
          deleteDriverFulfilled: "تم حذف السائق بنجاح",
          deleteDriverRejected: "لا يمكن حذف السائق، يمكنك المحاولة لاحقًا",

          /////////////
          changeLanguage: "تغيير اللغة",
          darkMode: "وضع الظلام",
          lightMode: "وضع الضوء",
          noMessages: "لا توجد رسائل متاحة",
          sendMessageFulfilled: "تم إرسال الرسالة بنجاح!",
          sendMessageRejected: "لا يمكن إرسال الرسالة، يمكنك المحاولة لاحقًا!",
          updateAdditionalSalaryFulfilled: "تم تحديث بيانات المستخدم بنجاح.",
          updateAdditionalSalaryRejected: "حدث خطأ ما! يرجى المحاولة لاحقًا.",
          updateUserRejected: "حدث خطأ ما! يرجى المحاولة لاحقًا.",
          updateUserFulfilled: "تم تحديث معلومات المستخدم بنجاح.",
          deleteUserRejected: "لا يمكن حذف المستخدم، يمكنك المحاولة لاحقًا",
          deleteUserFulfilled: "تم حذف المستخدم بنجاح",
          registerUserFulfilled: "تمت إضافة المستخدم بنجاح",
          registerUserRejected: "حدث خطأ ما! يرجى المحاولة لاحقًا",
          profileImageFulfilled: "تم تحميل صورة ملف تعريف المستخدم بنجاح!",
          profileImageRejected: "حدث خطأ ما! يرجى المحاولة لاحقًا",
          createUserInvoiceFulfilled: "تم إضافة فاتورة الموظف بنجاح",
          createUserInvoiceRejected: "حدث خطأ ما! يرجى المحاولة لاحقًا.",
          /////////////
          addSpendTypeFulfilled: "تمت إضافة نوع الإنفاق بنجاح",
          addSpendTypeRejected:
            "لا يمكن إضافة نوع الإنفاق، يمكنك المحاولة لاحقًا",
          deleteSpendTypeFulfilled: "تم حذف نوع الإنفاق بنجاح",
          deleteSpendTypeRejected:
            "لا يمكن حذف نوع الإنفاق، يمكنك المحاولة لاحقًا",
          /////////////
          createPettyCashFulfilled: "تمت إضافة النقد الصغير بنجاح",
          createPettyCashRejected:
            "لا يمكن إضافة النقد الصغير، يمكنك المحاولة لاحقًا",
          /////////////
          createCompanyIncomeFulfilled: "تمت إضافة الدخل بنجاح",
          createCompanyIncomeRejected:
            "لا يمكن إضافة الدخل، يمكنك المحاولة لاحقًا",
          /////////////
          addCompanyFilesFulfilled: "تمت إضافة ملف الشركة بنجاح",
          saveCompanyFilesSuccess: "تم حفظ ملف الشركة بنجاح",
          addCompanyFilesRejected:
            "لا يمكن إضافة ملف الشركة، يمكنك المحاولة لاحقًا",
          deleteCompanyFileFulfilled: "تم حذف ملف الشركة بنجاح",
          deleteCompanyFileRejected:
            "لا يمكن حذف ملف الشركة، يمكنك المحاولة لاحقًا",
          /////////////
          createBankStatementFulfilled: "تمت إضافة كشف حساب البنك بنجاح",
          createBankStatementRejected:
            "لا يمكن إضافة كشف حساب البنك، يمكنك المحاولة لاحقًا",
          fetchBankAccountsRejected:
            "حدث خطأ في استرجاع حسابات البنك، يمكنك المحاولة لاحقًا",
          /////////////
          createDriverInvoiceFulfilled: "تمت إضافة فاتورة السائق بنجاح",
          createDriverInvoiceRejected:
            "لا يمكن إضافة فاتورة السائق، يمكنك المحاولة لاحقًا",
          updateDriverInvoiceFulfilled: "تم تحديث فاتورة السائق بنجاح",
          updateDriverInvoiceRejected:
            "لا يمكن تحديث فاتورة السائق، يمكنك المحاولة لاحقًا",
          updateEmployeeInvoiceFulfilled: "تم تحديث فاتورة المستخدم بنجاح",
          updateEmployeeInvoiceRejected:
            "لا يمكن تحديث فاتورة المستخدم، يمكنك المحاولة لاحقًا",
          resetDriverInvoicesFulfilled: "تمت إعادة تعيين الفواتير بنجاح",
          resetDriverInvoicesRejected:
            "لا يمكن إعادة تعيين الفواتير، يمكنك المحاولة لاحقًا",
          resetSingleDriverInvoiceFulfilled:
            "تمت إعادة تعيين فواتير السائق بنجاح",
          resetSingleDriverInvoiceRejected:
            "لا يمكن إعادة تعيين فواتير السائق، يمكنك المحاولة لاحقًا",
          updateInvoiceDetailsFulfilled: "تم تحديث تفاصيل الفاتورة بنجاح",
          updateInvoiceDetailsRejected: "فشل تحديث تفاصيل الفاتورة",
          invoicesResetByOtherUser:
            "تم إعادة تعيين الفواتير بواسطة أحد المستخدمين",
          resetInvoicesFulfilled: "تم إعادة تعيين الفواتير بنجاح",
          resetInvoicesRejected: "فشل إعادة تعيين الفواتير",

          deductionAddHeading: "({{targetName}}) تنبيه الخصم على",
          deductionAddMessage: "{{date}} قدم طلب خصم بتاريخ {{senderName}}",

          deductionApproveHeading: "({{targetName}}) تنبيه الموافقة على الخصم",
          deductionApproveMessage:
            "{{date}} وافق على طلب الاستقطاع بتاريخ {{senderName}}",

          deactivationHeading: "({{targetName}}) تنبيه التعطيل",
          deactivationMessage:
            "{{date}} قام بتعطيل السائق بتاريخ {{senderName}}",

          activationHeading: "({{targetName}}) تنبيه التنشيط",
          activationMessage: "{{date}} قام بتفعيل السائق بتاريخ {{senderName}}",

          newMessageHeading: "({{senderName}}) تنبيه رسالة جديدة من",
          newMessageMessage: "{{date}} أرسل لك رسالة بتاريخ {{senderName}}",

          civilIdExpiryHeading:
            "({{targetName}}) تنبيه انتهاء صلاحية البطاقة المدنية",
          civilIdExpiryMessage: "{{date}} سوف تنتهي صلاحية البطاقة المدنية في",

          passportExpiryHeading:
            "({{targetName}}) تنبيه انتهاء صلاحية جواز السفر",
          passportExpiryMessage: "{{date}} سوف تنتهي صلاحية جواز السفر في",

          healthInsuranceExpiryHeading:
            "({{targetName}}) تنبيه انتهاء التأمين الصحي",
          healthInsuranceExpiryMessage:
            "{{date}} سوف تنتهي صلاحية التأمين الصحي في",

          contractExpiryHeading: "({{targetName}}) تنبيه انتهاء العقد",
          contractExpiryMessage: "{{date}} سينتهي العقد في",
          /////////////
          archiveFormTitle: "نموذج الأرشيف",
          archiveFormSubtitle: "إضافة ملف جديد إلى الأرشيف",
          fullName: "الاسم الكامل",
          company: "الشركة",
          workNumber: "الرقم الوظيفي",
          archiveNumber: "رقم الأرشيف",
          fullNameIsRequired: "الاسم الكامل مطلوب",
          companyIsRequired: "اسم الشركة مطلوب",
          idNumberIsRequired: "رقم الهوية مطلوب",
          vehicleIsRequired: "نوع المركبة مطلوب",
          workNumberIsRequired: "الرقم الوظيفي مطلوب",
          archiveNumberIsRequired: "رقم الأرشيف مطلوب",
          addToArchive: "إضافة إلى الأرشيف",
          archiveForm: "نموذج الأرشيف",
          searchArchiveTitle: "بحث في الأرشيف",
          searchArchiveSubtitle: "البحث عن الملفات في الأرشيف",
          addArchiveFulfilled: "تم إضافة ملف الأرشيف بنجاح",
          addArchiveRejected:
            "لم يتم إضافة الملف للأرشيف، يرجى المحاولة لاحقاً",
          deleteArchiveFulfilled: "تم حذف الملف من الأرشيف",
          deleteArchiveRejected: "فشل حذف الملف",
          noFileAvailable: "لا يوجد ملف في الأرشيف",
          backToMainSystem: "الرجوع إلى صفحة النظام",
          totalOrdersThisMonth: "إجمالي الطلبات هذا الشهر",
          totalCashThisMonth: "إجمالي النقود هذا الشهر",
          totalHoursThisMonth: "إجمالي الساعات هذا الشهر",
          updateArchiveFulfilled: "تم تحديث الملف بنجاح",
          updateArchiveRejected: "لا يمكن تحديث الملف، يرجى المحاولة لاحقاً",
          updateFile: "تحديث الملف",
          selectName: "اختر الاسم",
          updateError: "حدث خطأ ما! يرجى المحاولة لاحقاً",
          fileUpdateError: "حدث خطأ ما! يرجى المحاولة لاحقاً",

          /////////////
          deliveryManagement: "نظام إدارة التوصيل",
          landingPageHero:
            "قم بتبسيط عمليات التوصيل الخاصة بك مع نظام الإدارة الشامل لدينا. تتبع السائقين وإدارة الطلبات وتحسين المسارات في الوقت الفعلي.",
          getStarted: "ابدأ الآن",
          keyFeatures: "الميزات الرئيسية",
          efficientDelivery: "توصيل فعال",
          efficientDeliveryDesc:
            "تحسين المسارات وإدارة عمليات التوصيل بكفاءة مع نظام إدارة التوصيل المتقدم لدينا.",
          realTimeTracking: "التتبع في الوقت الفعلي",
          realTimeTrackingDesc:
            "تتبع السائقين وعمليات التوصيل في الوقت الفعلي مع تحديثات دقيقة للموقع ومراقبة الحالة.",
          driverManagement: "إدارة السائقين",
          driverManagementDesc:
            "إدارة شاملة للسائقين مع الملفات الشخصية والجداول الزمنية وتتبع الأداء.",
          readyToStart: "هل أنت مستعد لتحسين عمليات التوصيل الخاصة بك؟",
          startNow: "ابدأ الآن",
          invoiceManagement: "إدارة الفواتير",
          invoiceManagementDesc:
            "إدارة وتتبع جميع فواتير التوصيل والمدفوعات والسجلات المالية بكفاءة في نظام مركزي واحد.",
          uploadExcel: "Excel تحميل ملف",
          createArchivedDriverInvoiceRejected:
            "لا يمكن إضافة فواتير السائق، يمكنك المحاولة لاحقًا",
          createArchivedDriverInvoiceFulfilled: "تم إضافة فواتير السائق بنجاح",
          confirmResetMessage: "هل أنت متاكد من إعادة تعيين الفواتير؟",
          confirmReset: "تأكيد",
          confirmDeleteMessage: "هل أنت متاكد من حذف هذا الملف؟",
          confirmDelete: "تأكيد",
          confirm: "تأكيد",
          confirmApproveDeduction: "هل أنت متاكد من الموافقة على طلب الخصم؟",
          confirmRejectDeduction: "هل أنت متاكد من رفض طلب الخصم؟",
          confirmDeactivate: "تأكيد إيقاف تفعيل السائق",
          confirmDeactivateMessage: "هل أنت متاكد من إيقاف تفعيل هذا السائق؟",
          confirmDeleteDriver: "تأكيد حذف السائق",
          confirmDeleteDriverMessage: "هل أنت متاكد من حذف هذا السائق؟",
          confirmReactivate: "تأكيد إعادة تفعيل السائق",
          confirmReactivateMessage: "هل أنت متاكد من إعادة تفعيل هذا السائق؟",

          // Driver Schema Validations
          phoneAlreadyExists: "رقم الهاتف موجود بالفعل في النظام",
          firstNameRequired: "الاسم الأول مطلوب",
          lastNameRequired: "اسم العائلة مطلوب",
          phoneRequired: "رقم الهاتف مطلوب",
          idNumberRequired: "رقم الهوية مطلوب",
          idExpiryDateRequired: "تاريخ انتهاء الهوية مطلوب",
          passportNumberRequired: "رقم جواز السفر مطلوب",
          passportExpiryDateRequired: "تاريخ انتهاء جواز السفر مطلوب",
          contractExpiryDateRequired: "تاريخ انتهاء العقد مطلوب",
          driverLicenseExpiryDateRequired: "تاريخ انتهاء رخصة القيادة مطلوب",
          carPlateNumberRequired: "رقم لوحة السيارة مطلوب",
          carRegisterationExpiryDateRequired:
            "تاريخ انتهاء تسجيل السيارة مطلوب",
          carRegisterationRequired: "تسجيل السيارة مطلوب",
          gasCardRequired: "بطاقة الوقود مطلوبة",
          healthInsuranceExpiryDateRequired: "تاريخ انتهاء التأمين الصحي مطلوب",
          employeeCompanyNumberRequired: "رقم الموظف في الشركة مطلوب",
          ibanRequired: "رقم الآيبان مطلوب",
          bankNameRequired: "اسم البنك مطلوب",
          positionRequired: "المسمى الوظيفي مطلوب",
          vehicleRequired: "نوع المركبة مطلوب",
          contractTypeRequired: "نوع العقد مطلوب",
          talabatIdRequired: "رقم طلبات مطلوب",
          mainSalaryRequired: "الراتب الأساسي مطلوب",
          passportAlreadyExists: "رقم جواز السفر موجود بالفعل في النظام",
          identificationAlreadyExists: "رقم الهوية موجود بالفعل في النظام",
          emailAlreadyExists: "البريد الإلكتروني موجود بالفعل في النظام",
          passwordsNotMatch: "كلمتا السر غير متطابقتين",
          restore: "استرجاع فواتير هذا الشهر",
          restoreInvoicesFulfilled: "تم استرجاع فواتير هذا الشهر بنجاح",
          restoreInvoicesRejected:
            "لا يمكن استرجاع فواتير هذا الشهر، يمكنك المحاولة لاحقًا",
          confirmRestore: "تأكيد استرجاع الفواتير",
          confirmRestoreMessage: "هل أنت متاكد من استرجاع فواتير هذا الشهر؟",
          mainOrder: "الطلب الرئيسي",
          additionalOrder: "الطلب الإضافي",
          hour: "الساعة",
          close: "إغلاق",
          clear: "مسح",
          initialize: "تصفير",
          excelColumnNote: "sepndType يجب أن يحتوي الملف على عامود باسم",
          //////////////////
          salaryReportTitle: "تقرير الرواتب",
          salaryReportSubtitle: "تقرير الرواتب لجميع الموظفين والسائقين",
          salaryReport: "تقرير الرواتب",
          totalSalary: "إجمالي الرواتب",
          bankName: "اسم البنك",
          position: "المسمى الوظيفي",
          salary: "الراتب",
          Administrative: "إداري",
          Engineer: "مهندس",
          CEO: "الرئيس التنفيذي",
          COO: "المدير التنفيذي للعمليات",
          CFO: "المدير المالي التنفيذي",
          CTO: "المدير التنفيذي للتكنولوجيا",
          MarketingManager: "مدير التسويق",
          SalesManager: "مدير المبيعات",
          HRManager: "مدير الموارد البشرية",
          ProjectManager: "مدير المشروع",
          ProductManager: "مدير المنتج",
          BusinessAnalyst: "محلل الأعمال",
          SoftwareEngineer: "مهندس البرمجيات",
          WebDeveloper: "مطور الويب",
          GraphicDesigner: "مصمم الجرافيك",
          ContentWriter: "كاتب المحتوى",
          CustomerSupportRepresentative: "ممثل دعم العملاء",
          DataAnalyst: "محلل البيانات",
          OperationsManager: "مدير العمليات",
          AdminAssistant: "مساعد إداري",
          TeamLeader: "قائد الفريق",
          MarketingSpecialist: "أخصائي التسويق",
          LegalAdvisor: "مستشار قانوني",
          ITSupportSpecialist: "أخصائي دعم تكنولوجيا المعلومات",
          Receptionist: "موظف الاستقبال",
          Intern: "متدرب",
          Representative: "ممثل الشركة (مندوب)",
          download: "تحميل",
          startDay: "من يوم",
          endDay: "إلى يوم",
          invalidPosition: "المسمى الوظيفي غير موجود في النظام",
          "Car Driver": "سائق سيارة",
          "Motorcycle Driver": "سائق سيكل",
          ///////////////////
          "National Bank of Kuwait (NBK)": "(NBK) بنك الكويت الوطني",
          "Kuwait Finance House (KFH)": "(KFH) بيت التمويل الكويتي",
          "Burgan Bank": "بنك برقان",
          "Gulf Bank": "بنك الخليج",
          "Commercial Bank of Kuwait": "(CBK) بنك التجاري",
          "Al Ahli Bank of Kuwait": "البنك الأهلي الكويتي",
          "Boubyan Bank": "بنك بوبيان",
          "Kuwait International Bank": "بنك الكويت الدولي",
          "Warba Bank": "بنك وربة",
          "Industrial Bank of Kuwait": "بنك الكويت الصناعي",
          ///////////////////
          salaryConfigurationTitle: "إدارة الرواتب",
          salaryConfigurationSubtitle: "إدارة رواتب السائقين",
          salaryConfig: "إدارة الرواتب",
          minimumOrders: "الطلبات الأدنى",
          maximumOrders: "الطلبات الأقصى",
          multiplier: "المضاعف",
          fixedAmount: "المبلغ الثابت",
          saveChanges: "حفظ التغييرات",
          applyToMainOrdersOnly: "تطبيق على الطلبات الرئيسية",
          addRule: "إضافة سياسة",
          noRulesExist: "لا يوجد قواعد",
          invalidNumbersInRules:
            "تم العثور على أرقام غير صالحة في القواعد. يرجى التحقق من صحة جميع القيم.",
          overlapError:
            "تم العثور على تداخل بين القواعد: {{rule1}} و {{rule2}}. لا يمكن أن تتداخل القواعد.",
          gapError:
            "تم العثور على فجوة بين {{rule1}} و {{rule2}}. يجب أن تكون القواعد متصلة.",
          validationSuccess: "تم التحقق من صحة القواعد بنجاح!",
          createSalaryConfigFulfilled: "تم إضافة القواعد بنجاح",
          createSalaryConfigRejected:
            "لم يتم إضافة القواعد، يرجى المحاولة لاحقاً",
          updateSalaryConfigFulfilled: "تم تحديث القواعد بنجاح",
          updateSalaryConfigRejected:
            "لم يتم تحديث القواعد، يرجى المحاولة لاحقاً",
          deleteSalaryConfigFulfilled: "تم حذف القواعد بنجاح",
          deleteSalaryConfigRejected:
            "لم يتم حذف القواعد، يرجى المحاولة لاحقاً",
          ////////////////////
          driversSalaryDetailsTitle: "تفاصيل الرواتب الشهرية",
          driversSalaryDetailsSubtitle:
            "تفاصيل الرواتب الشهرية لجميع الإداريين والسائقين",
          driversSalaryDetails: "تفاصيل الرواتب الشهرية",
          cashSalary: "سداد نقدي",
          summary: "ملخص",
          netSalaryAfterDeductions: "تحويل بنكي",
          specialBikeDriverSummary: "ملخص السائق حاكيكات حميد",
          allBikeDriversSummary: "ملخص سائقون الدراجات",
          allDriversSummary: "ملخص جميع السائقون (سيارات + دراجات)",
          finalSummary: "ملخص إجمالي",
          totalDriversNetSalary: "إجمالي الرواتب الصافية للسائقين",
          totalDriversCashPayment: "إجمالي السداد النقدي للسائقين",
          totalBankTransfer: "إجمالي التحويل البنكي",
          grandTotal: "إجمالي المبلغ",
          deductions: "الاستقطاعات",
          cashPayment: "السداد النقدي",
          allEmployeesSummary: "ملخص جميع الإداريين",
          totalBankTransferAllStaff:
            "إجمالي التحويل البنكي للإداريين + السائقين",
          totalCashPaymentAllStaff: "إجمالي السداد النقدي للإداريين + السائقين",
        },
      },
    },
  });

export default i18n;
